.App {
  text-align: center;
}

.ant-spin-spinning {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.site-layout-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px - 70px);
  padding: 24px;
  background: #fff;
}

.ant-menu-horizontal {
  justify-content: flex-end;
}

.ant-menu-item.wallet {
  background: #1890ff;
}

.ant-menu-item.wallet .ant-menu-title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ant-menu-item.wallet .ant-typography {
  color: #fff;
  margin-bottom: 0;
  margin-left: 5px;
}

.ant-menu-item.wallet img {
  width: 30px;
  filter: invert(1);
}

.ant-form-item {
  flex-wrap: nowrap;
}

.ant-form-item-control {
  min-width: 300px;
}

.ant-input-number {
  width: 100%;
}

.transaction-type {
  background: #52c41a;
}

.transaction-type[value=false] {
  background: #f5222d;
}

.ant-layout-footer {
  text-align: center;
}
